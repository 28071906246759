import { createSelector } from 'reselect';

import { RootState } from '../types';

const selectPayment = (state: RootState) => state.payment;

const selectIsLoading = createSelector(selectPayment, (payment) => payment.isLoading);
const selectPaymentMethod = createSelector(selectPayment, (payment) => payment.paymentMethod);
const selectPaymentConfigPaypal = createSelector(selectPayment, (payment) => payment.paymentConfig.paypal);
const selectPaymentConfigApplePay = createSelector(selectPayment, (payment) => payment.paymentConfig.applaPay);
const selectPaymentConfigSandbox = createSelector(selectPayment, (payment) => payment.paymentConfig.sandbox);
const selectSolidInited = createSelector(selectPayment, (payment) => payment.solidInited);
const selectCheckoutOrderId = createSelector(selectPayment, (payment) => payment.checkoutOrderId);
const selectCurrentProduct = createSelector(selectPayment, (payment) => payment.currentProduct);
const selectCurrency = createSelector(selectPayment, (payment) => payment.currency);
const selectUpsellId = createSelector(selectPayment, (payment) => payment.upsellId);
const selectOneClickPaymentPrice = createSelector(selectPayment, (payment) => payment.oneClickPaymentPrice);
const selectIsPaymentScreen = createSelector(selectPayment, (payment) => payment.isPaymentScreen);

const selectIsDisplayCustomPaymentButton = createSelector(
    selectPayment,
    ({ isCustomPaymentButton, isHideCustomPaymentButton }) => isCustomPaymentButton || isHideCustomPaymentButton
);
const selectIsApplePayAvailable = createSelector(selectPayment, (payment) => payment.isApplePayAvailable);
const selectSkipTrialProduct = createSelector(selectPayment, (payment) => payment.skipTrialProduct);
const selectSubscriptionId = createSelector(selectPayment, (payment) => payment.subscriptionId);
const selectIsGooglePayAvailable = createSelector(selectPayment, (payment) => payment.isGooglePayAvailable);
const selectIsLatamOn = createSelector(selectPayment, (payment) => payment.isLatamOn);
const selectIsUpsellPaid = createSelector(selectPayment, (payment) => payment.isUpsellPaid);
const selectIsUpsellSubPaid = createSelector(selectPayment, (payment) => payment.isUpsellSubPaid);
const selectGuidesProduct = createSelector(selectPayment, (payment) => payment.guidesProduct);

export {
    selectPayment,
    selectCheckoutOrderId,
    selectCurrency,
    selectCurrentProduct,
    selectIsLoading,
    selectPaymentConfigPaypal,
    selectPaymentConfigApplePay,
    selectPaymentConfigSandbox,
    selectPaymentMethod,
    selectSolidInited,
    selectUpsellId,
    selectOneClickPaymentPrice,
    selectIsPaymentScreen,
    selectIsDisplayCustomPaymentButton,
    selectIsApplePayAvailable,
    selectSkipTrialProduct,
    selectSubscriptionId,
    selectIsGooglePayAvailable,
    selectIsLatamOn,
    selectIsUpsellPaid,
    selectIsUpsellSubPaid,
    selectGuidesProduct,
};
