/* eslint-disable max-lines */
export const paymentRoutes = [
    {
        path: 'PaymentLandingPages/PaymentG1Foxtrot3',
        route: 'payment-g1-foxtrot3',
    },
    {
        path: 'PaymentLandingPages/PaymentA1Foxtrot3',
        route: 'offer-a1-foxtrot3',
    },
    {
        path: 'PaymentLandingPages/PaymentG1Golf/PaymentG1Golf3',
        route: 'payment-g1-golf3',
    },
    {
        path: 'PaymentLandingPages/PaymentA1Golf/PaymentA1Golf3',
        route: 'offer-a1-golf3',
    },
    {
        path: 'PaymentLandingPages/PaymentG1Golf/PaymentG1Golf4',
        route: 'payment-g1-golf4',
    },
    {
        path: 'PaymentLandingPages/PaymentA1Golf/PaymentA1Golf4',
        route: 'offer-a1-golf4',
    },
    {
        path: 'PaymentLandingPages/PaymentG1Foxtrot3Full',
        route: 'payment-g1-foxtrot3-full',
    },
    {
        path: 'PaymentLandingPages/PaymentA1Foxtrot3V2',
        route: 'offer-a1-foxtrot3-v2',
    },
    {
        path: 'PaymentLandingPages/PaymentG1Foxtrot15',
        route: 'payment-g1-foxtrot15',
    },
    {
        path: 'PaymentLandingPages/PaymentA1Foxtrot15',
        route: 'offer-a1-foxtrot15',
    },
    {
        path: 'PaymentLandingPages/PaymentG6Golf5',
        route: 'payment-g6-golf5',
    },
    {
        path: 'PaymentLandingPages/PaymentG9Golf11',
        route: 'payment-g9-golf11',
    },
    {
        path: 'PaymentLandingPages/PaymentA6Golf5',
        route: 'offer-a6-golf5',
    },
    {
        path: 'PaymentLandingPages/PaymentA9Golf11',
        route: 'offer-a9-golf11',
    },
    {
        path: 'PaymentLandingPages/PaymentG6Golf7',
        route: 'payment-g6-golf7',
    },
    {
        path: 'PaymentLandingPages/PaymentA6Golf7',
        route: 'offer-a6-golf7',
    },
    {
        path: 'PaymentLandingPages/PaymentG9Golf6',
        route: 'payment-g9-golf6',
    },
    {
        path: 'PaymentLandingPages/PaymentA9Golf6',
        route: 'offer-a9-golf6',
    },
    {
        path: 'PaymentLandingPages/PaymentG6Golf8',
        route: 'payment-g6-golf8',
    },
    {
        path: 'PaymentLandingPages/PaymentA6Golf8',
        route: 'offer-a6-golf8',
    },
    {
        path: 'PaymentLandingPages/PaymentG8Golf5',
        route: 'payment-g8-golf5',
    },
    {
        path: 'PaymentLandingPages/PaymentE1Golf12',
        route: 'payment-e1-golf12',
    },
    {
        path: 'PaymentLandingPages/SkipTrial',
        route: 'skip-trial',
    },
    {
        path: 'PaymentLandingPages/OfferM1Golf5',
        route: 'offer-m1-golf5',
    },
    {
        path: 'PaymentLandingPages/PaymentM1Golf5',
        route: 'payment-m1-golf5',
    },
    {
        path: 'PaymentLandingPages/PaymentG6Golf14',
        route: 'payment-g6-golf14',
    },
    {
        path: 'PaymentLandingPages/PaymentA6Golf14',
        route: 'offer-a6-golf14',
    },
    {
        path: 'PaymentLandingPages/PaymentG10Golf14',
        route: 'payment-g10-golf14',
    },
    {
        path: 'PaymentLandingPages/PaymentG7Golf5',
        route: 'payment-g7-golf5',
    },
    {
        path: 'PaymentLandingPages/PaymentA7Golf5',
        route: 'offer-a7-golf5',
    },
    {
        path: 'PaymentLandingPages/PaymentG12Golf14',
        route: 'payment-g12-golf14',
    },
    {
        path: 'PaymentLandingPages/OfferG12Golf14',
        route: 'offer-g12-golf14',
    },
    {
        path: 'PaymentLandingPages/PaymentG7Golf14',
        route: 'payment-g7-golf14',
    },
    {
        path: 'PaymentLandingPages/OfferA7Golf14',
        route: 'offer-a7-golf14',
    },
    {
        path: 'PaymentLandingPages/ConfigurablePaymentG11Golf14',
        route: 'payment-g11-golf14',
    },
    {
        path: 'PaymentLandingPages/PaymentA6Golf16',
        route: 'payment-g6-golf16',
    },
    {
        path: 'PaymentLandingPages/OfferA6Golf16',
        route: 'offer-a6-golf16',
    },
    {
        path: 'PaymentLandingPages/ConfigurablePaymentG11Golf14',
        route: 'payment-g11-golf14',
    },
    {
        path: 'PaymentLandingPages/PaymentG14Golf14',
        route: 'payment-g14-golf14',
    },
    {
        path: 'PaymentLandingPages/OfferA14Golf14',
        route: 'offer-a14-golf14',
    },

    {
        path: 'PaymentLandingPages/PaymentG13Golf13',
        route: 'payment-g13-golf13',
    },
    {
        path: 'PaymentLandingPages/OfferG13Golf13',
        route: 'offer-g13-golf13',
    },
    {
        path: 'PaymentLandingPages/PaymentI1Golf17',
        route: 'payment-i1-golf17',
    },
    {
        path: 'PaymentLandingPages/OfferI1Golf17',
        route: 'offer-i1-golf17',
    },
    {
        path: 'PaymentLandingPages/PaymentG12Golf15',
        route: 'payment-g12-golf15',
    },
    {
        path: 'PaymentLandingPages/OfferA12Golf15',
        route: 'offer-a12-golf15',
    },
];
