import { InitConfig } from '@solidgate/client-sdk-loader';

import * as actionTypes from './actionTypes';

import { ValidatePayload, ValidateResponse } from 'types/payments/validate';
import { Init } from 'types/payments/paymentSystemsInit';
import { CurrentProduct, PaymentMethod } from 'types/payments/payments';
import { IPaymentFailToReteno } from 'types/payments/paymentApiInterfaces';
import { IInitOneClickPayment } from 'types/payments/oneClickPayment';
import { IBraintreeInit } from 'types/commonInterfaces';

import { Currency } from '../sagas/user/interfaces';

export const setCurrentProduct = (product: CurrentProduct | null) => {
    return {
        type: actionTypes.SET_CURRENT_PRODUCT,
        payload: product,
    } as const;
};

export const init = (data: Init) => {
    return {
        type: actionTypes.INIT_PAYMENT,
        payload: data,
    } as const;
};

export const initPayPal = (data: Init) => {
    return {
        type: actionTypes.INIT_PAY_PAL,
        payload: data,
    } as const;
};

export const setLoadingStatus = (status: boolean) => {
    return {
        type: actionTypes.SET_LOADING_STATUS,
        payload: status,
    } as const;
};

export const changeExclusiveOfferState = (status: boolean) => {
    return {
        type: actionTypes.SET_EXCLUSIVE_OFFER,
        payload: status,
    } as const;
};

export const validatePayment = (payload: ValidatePayload) => {
    return {
        type: actionTypes.VALIDATE_PAYMENT,
        payload: payload,
    } as const;
};

/* =================*/

export const changePaymentMethod = (type: PaymentMethod) => {
    return {
        type: actionTypes.CHANGE_PAYMENT_TYPE,
        payload: type,
    } as const;
};

export const setPaymentConfig = (payload: { [payment: string]: boolean }) => {
    return {
        type: actionTypes.SET_PAYMENT_CONFIG,
        payload: payload,
    } as const;
};

export const setCurrency = (currency: Currency) => {
    return {
        type: actionTypes.SET_PAYMENT_CURRENCY,
        payload: currency,
    } as const;
};

export const setUpsellProductPrice = (price: number) => {
    return {
        type: actionTypes.SET_UPSELL_PRODUCT_PRICE,
        payload: price,
    } as const;
};

export const setUpsellProductId = (upsellId: string) => {
    return {
        type: actionTypes.SET_UPSELL_PRODUCT_ID,
        payload: upsellId,
    } as const;
};

export const initOneClickPayment = (data: IInitOneClickPayment) => {
    return {
        type: actionTypes.INIT_ONE_CLICK_PAYMENT,
        payload: data,
    } as const;
};

export const setPaymentDiscount = (discount: number) => {
    return {
        type: actionTypes.SET_PAYMENT_DISCOUNT,
        payload: discount,
    } as const;
};

export const setIsPaymentScreen = (isPayment: boolean) => {
    return {
        type: actionTypes.SET_IS_PAYMENT_SCREEN,
        payload: isPayment,
    } as const;
};

export const setOrderId = (orderId: string) => {
    return {
        type: actionTypes.SET_ORDER_ID,
        payload: orderId,
    } as const;
};

export const setSubscriptionId = (subscriptionId: string) => {
    return {
        type: actionTypes.SET_SUBSCRIPTION_ID,
        payload: subscriptionId,
    } as const;
};

export const setSolidInited = (solidInited: boolean) => {
    return {
        type: actionTypes.INIT_SOLID_PAYMENT,
        payload: solidInited,
    } as const;
};

export const setInitPaymentData = (initPaymentData: object) => {
    return {
        type: actionTypes.SET_PAYMENT_DATA,
        payload: initPaymentData,
    } as const;
};

export const setValidatePaymentData = (validatePaymentData: ValidateResponse) => {
    return {
        type: actionTypes.VALIDATE_PAYMENT_DATA,
        payload: validatePaymentData,
    } as const;
};

export const setBankCardSrc = (bankCardSrc: string) => {
    return {
        type: actionTypes.SET_BANKCARD_SRC,
        payload: bankCardSrc,
    } as const;
};

export const setValidatePaymentFail = (error: object) => {
    return {
        type: actionTypes.VALIDATE_PAYMENT_FAIL,
        payload: error,
    } as const;
};

export const setPaymentType = (paymentType: string) => {
    return {
        type: actionTypes.SET_PAYMENT_TYPE,
        payload: paymentType,
    } as const;
};

export const setCustomPaymentButton = (status: boolean) => {
    return {
        type: actionTypes.SET_CUSTOM_PAYMENT_BUTTON,
        payload: status,
    } as const;
};

export const setIsLoadingBankCard = (isLoading: boolean) => {
    return {
        type: actionTypes.SET_IS_LOADING_BANK_CARD,
        payload: isLoading,
    } as const;
};

export const setMerchantData = (data: InitConfig['merchantData']) => {
    return {
        type: actionTypes.SET_MERCHANT_DATA,
        payload: data,
    } as const;
};

export const initPaymentGenerator = (data: Init) => {
    return {
        type: actionTypes.INIT_PAYMENT_GENERATOR,
        payload: data,
    } as const;
};

export const initBrainTree = (payload: IBraintreeInit) => {
    return {
        type: actionTypes.INIT_BRAIN_TREE,
        payload,
    } as const;
};

export const setHideCustomPaymentButton = (status: boolean) => {
    return {
        type: actionTypes.SET_HIDE_CUSTOM_PAYMENT_BUTTON,
        payload: status,
    } as const;
};

export const setIsApplePayAvailable = (isApplePayAvailable: boolean) => {
    return {
        type: actionTypes.SET_IS_APPLE_PAY_AVAILABLE,
        payload: isApplePayAvailable,
    } as const;
};

export const setPaymentErrorCode = (retenoErrorObj: IPaymentFailToReteno) => {
    return {
        type: actionTypes.SET_PAYMENT_RETENO_ERROR,
        payload: retenoErrorObj,
    } as const;
};

export const setPaymentErrorToStore = (retenoErrorObj: IPaymentFailToReteno) => {
    return {
        type: actionTypes.SET_PAYMENT_RETENO_ERROR_STORE,
        payload: retenoErrorObj,
    } as const;
};

export const setSkipTrialProduct = (product: CurrentProduct) => {
    return {
        type: actionTypes.SET_SKIP_TRIAL_PRODUCT,
        payload: product,
    } as const;
};

export const skipTrialSubscription = () => {
    return {
        type: actionTypes.SKIP_TRIAL_SUBSCRIPTION,
    } as const;
};

export const setIsGooglePayAvailable = (isGooglePayAvailable: boolean) => {
    return {
        type: actionTypes.SET_IS_GOOGLE_PAY_AVAILABLE,
        payload: isGooglePayAvailable,
    } as const;
};

export const setAllProductsFromFirstPayment = (status: CurrentProduct[]) => {
    return {
        type: actionTypes.SET_ALL_PRODUCTS_FROM_FIRST_PAYMENT,
        payload: status,
    } as const;
};

export const setIsLatamOn = (status: boolean) => {
    return {
        type: actionTypes.SET_IS_LATAM_ON,
        payload: status,
    } as const;
};

export const setIsUpsellPaid = (status: boolean) => {
    return {
        type: actionTypes.SET_IS_UPSELL_PAID,
        payload: status,
    } as const;
};

export const setIsUpsellSubPaid = (status: boolean) => {
    return {
        type: actionTypes.SET_IS_UPSELL_SUB_PAID,
        payload: status,
    } as const;
};

export const setGuidesProduct = (product: CurrentProduct | null) => {
    return {
        type: actionTypes.SET_GUIDES_PRODUCT,
        payload: product,
    } as const;
};
